import { CheckBoxIcon } from 'assets/icons';
import classNames from 'classnames';
import { useState } from 'react';

interface OptionProps {
  label: string;
  value: number | string;
  name?: string;
  id?: string;
  active?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

export const Option = ({
  label,
  name,
  value,
  active,
  id = '',
  onChange,
  disabled,
}: OptionProps) => {
  const [checked, setChecked] = useState(active);

  const handleChange = (value: any) => {
    setChecked(!checked);
    onChange && onChange(value);
  };

  const defaultClasses = 'after:border-gray-medium';
  const disabledClasses = 'after:bg-gray-light after:border-gray-medium';
  const checkedClasses = 'after:border-primary-60';
  const checkedDisabledClasses = 'after:border-gray-medium after:bg-gray-light';

  const _class =
    checked && disabled
      ? checkedDisabledClasses
      : checked
      ? checkedClasses
      : disabled
      ? disabledClasses
      : defaultClasses;

  return (
    <label
      className={`check-hover flex cursor-pointer gap-2 p-2 transition-all hover:bg-light-30 ${
        disabled ? 'pointer-events-none' : ''
      }`}
      htmlFor={`option_${id}`}
    >
      <span
        className={`flex items-center justify-center after:block after:h-4 after:w-4 after:rounded after:border after:transition-all ${_class}`}
      >
        {checked ? (
          <CheckBoxIcon
            className={classNames('absolute h-6 w-6 text-primary-60', { 'text-text-50': disabled })}
          />
        ) : null}
      </span>
      <span className="text-xs">{label}</span>
      <input
        className="input-default absolute appearance-none"
        type="checkbox"
        id={`option_${id}`}
        checked={checked}
        onChange={() => handleChange(id)}
        value={value}
        name={name}
        disabled={disabled}
      />
    </label>
  );
};
