import useDebounce from 'hooks/useDebounce';
import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { getExtendedSuggestions } from 'services';
import { ExtendedSuggestion, SettlementShort } from 'types';
import { Select } from 'ui/redesign/Select';
import { OptionType } from 'ui/redesign/Select/types';
import { SettlementsSelectProps } from './types';
import { ProfileService } from 'services/ProfileService';
import { toast } from 'react-toastify';

export const CitiesSelect: FC<SettlementsSelectProps> = ({
  control,
  isMulti = false,
  name,
  label,
  onChange,
  setValue,
  isFormDisabled,
  clearErrors,
  ...rest
}) => {
  const [settlements, setSettlements] = useState<ExtendedSuggestion[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCities('');
    return () => {
      setIsLoading(false);
    };
  }, []);

  const getCities = async (template: string) => {
    setIsLoading(true);
    const response = await getExtendedSuggestions(template, 1);
    if (response.status === 200) {
      setSettlements(response.data);
    }
    setIsLoading(false);
  };

  const debouncedSettlements = useDebounce(getCities, 500);

  const getUniqueCities = (
    citiesArr: ExtendedSuggestion[],
    valueArr?: OptionType<SettlementShort>[]
  ) => {
    let uniqueCities = citiesArr;
    valueArr &&
      valueArr.forEach((value) => {
        uniqueCities = uniqueCities.filter(
          (city) => city.suggestion.data.fias_id !== value.value.fiasId
        );
      });

    return uniqueCities;
  };

  const transformSuggestionToSelectOption = (array: ExtendedSuggestion[]) => {
    return Array.isArray(array)
      ? array.map((item) => ({
          label: item.suggestion.value,
          value: item,
          isDisabled: item.isMarked,
        }))
      : [];
  };

  const removeNotFoundCity = (
    value: MultiValue<OptionType<ExtendedSuggestion | SettlementShort>>,
    removedOptionValue: OptionType<ExtendedSuggestion>
  ) =>
    value.filter((optionValue) => {
      const value = optionValue.value as ExtendedSuggestion;
      return !(
        value.suggestion &&
        value.suggestion.data.fias_id === removedOptionValue.value.suggestion.data.fias_id
      );
    });

  return (
    <div className="field-select w-full max-w-[640px] px-[1px]">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              name={name}
              variant="cities"
              value={field.value}
              label={label}
              isMulti
              isDisabled={isFormDisabled}
              onChange={async (value, meta) => {
                field.onChange(value);
                clearErrors && clearErrors(name);

                const newOptionValue = meta.option as OptionType<ExtendedSuggestion> | undefined;
                if (
                  newOptionValue &&
                  value?.find((optionValue: OptionType<ExtendedSuggestion | SettlementShort>) => {
                    const value = optionValue.value as ExtendedSuggestion;
                    return (
                      value.suggestion &&
                      value.suggestion.data.fias_id === newOptionValue.value.suggestion.data.fias_id
                    );
                  }) !== undefined
                ) {
                  try {
                    const response = await ProfileService.getUserSettlementBySuggestion(
                      newOptionValue.value
                    );
                    if (response.data.isSuccess) {
                      const actualOptionValue = {
                        label: response.data.commonSettlement.objectFullName,
                        value: response.data.commonSettlement,
                      };
                      const newValue: MultiValue<OptionType<SettlementShort>> | null = value?.map(
                        (optionValue: OptionType<ExtendedSuggestion | SettlementShort>) => {
                          const value = optionValue.value as ExtendedSuggestion;
                          return value.suggestion &&
                            value.suggestion.data.fias_id === actualOptionValue.value.fiasId
                            ? actualOptionValue
                            : optionValue;
                        }
                      );
                      if (newValue) {
                        field.onChange(newValue);
                        onChange && onChange(newValue, meta);
                      }
                    } else {
                      toast.error(response.data.message);
                      field.onChange(
                        removeNotFoundCity(
                          value as MultiValue<OptionType<ExtendedSuggestion | SettlementShort>>,
                          newOptionValue
                        )
                      );
                    }
                  } catch (error) {
                    toast.error('Город/населённый пункт не найден');
                    field.onChange(
                      removeNotFoundCity(
                        value as MultiValue<OptionType<ExtendedSuggestion | SettlementShort>>,
                        newOptionValue
                      )
                    );
                  }
                } else {
                  onChange && onChange(value as MultiValue<OptionType<SettlementShort>>, meta);
                }
              }}
              options={transformSuggestionToSelectOption(getUniqueCities(settlements, field.value))}
              isLoading={isLoading}
              onInputChange={(newValue: string) => {
                if (newValue.length < 3) {
                  return;
                }
                debouncedSettlements(newValue);
              }}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};
