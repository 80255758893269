import { CheckBoxIcon } from 'assets/icons';
import classNames from 'classnames';
import { FC } from 'react';
import { ValidationError } from '../ValidationError';
import { CheckboxProps } from './types';

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  className,
  title,
  errorMessage = '',
  onChange,
  disabled,
  ...rest
}) => {
  const defaultClasses = 'after:border-text-50';
  const disabledClasses = 'after:border-gray-medium';
  const checkedClasses = 'after:border-text-50';
  const checkedDisabledClasses = 'after:border-gray-medium';

  const _class =
    checked && disabled
      ? checkedDisabledClasses
      : checked
      ? checkedClasses
      : disabled
      ? disabledClasses
      : defaultClasses;
  return (
    <div className={classNames(`flex flex-col sm:ml-0`, className)}>
      <div className="flex items-center">
        <label
          className={`hover:bg-fff flex cursor-pointer gap-2 p-2 transition-all ${
            disabled ? 'pointer-events-none' : ''
          }`}
          htmlFor={`checkbox`}
        >
          <span
            className={`flex items-center justify-center after:block after:h-5 after:w-5 after:rounded after:border after:transition-all ${_class}`}
          >
            {checked ? (
              <CheckBoxIcon
                className={classNames('absolute h-6 w-6 text-primary-60', {
                  'text-text-50': disabled,
                })}
              />
            ) : null}
          </span>
          <input
            className="input-default absolute appearance-none "
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            id={`checkbox`}
            {...rest}
          />
          <span className={classNames({ '!text-text-50': disabled })}>{title && title}</span>
        </label>
      </div>
      <ValidationError errorMessage={errorMessage} />
    </div>
  );
};
