import {
  ArchiveIcon,
  BankIcon,
  CaseIcon,
  CashIcon,
  EducationIcon,
  GerbIcon,
  MobileIcon,
  UserIcon,
} from 'assets/icons';
import { AxiosError } from 'axios';
import { ChatModeration } from 'components/ChatModeration';
import { format } from 'date-fns';
import { ApplicationType } from 'enums/applicationType';
import { ChatEntityType } from 'enums/chatEntityType';
import { ECitizenship } from 'enums/citizenship';
import { ExecutorTypeEnum } from 'enums/executorType';
import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { moderationRequestTitle } from 'pages/ModerationRequest/types';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { getExecutorNavigation } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getExecutorNavigation';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { getModerationRequestStatus } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestStatus';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { ModerationRequestService } from 'services/ModerationRequestService';
import {
  Education,
  ModerationRequest,
  WorkExperience,
} from 'services/ModerationRequestService/types';
import { RadioGroup } from 'ui/RadioGroup';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { Dropdown } from 'ui/redesign/Dropdown';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { formattedDate } from 'utils/helpers/formatDate.helper';
import { executorTypes } from '../../consts';
import { Step1Fields } from '../step1/Step1Fields';
import { checkBirthDate, checkName } from '../step1/consts';
import { Step2Fields } from '../step2/Step2Fields';
import {
  checkBirthPlace,
  checkDateOfIssue,
  checkExecutorPhotos,
  checkIssueBy,
  checkNumber,
  checkPassportFiles,
  checkRegistrationAddress,
  checkSeries,
  checkUnitCode,
} from '../step2/consts';
import { Step3Fields } from '../step3/Step3Fields';
import {
  checkBikAndBankSettlement,
  checkExecutorOgrnip,
  checkSelfemployedCertificate,
} from '../step3/consts';
import { Step4Fields } from '../step4/Step4Fields';
import { Step5Fields } from '../step5/Step5Fields';
import { Step6Fields } from '../step6/Step6Fields';
import { checkExperience } from '../step6/consts';
import { Step7Fields } from '../step7/Step7Fields';
import { Buttons } from './Buttons';
import { ShowAd } from './ShowAd';
import { RequestHandler } from './consts';
import { trimDescription } from './helpers';
import { ModerationRequestForm, ValidationError } from './types';
import {
  checkAcceptsOffer,
  checkExecutorSpecializations,
  checkServiceCities,
} from '../step4/consts';

export const FinalStep = () => {
  const moderationRequestData = useAppSelector(getModerationRequestData);
  const { status, serviceCities, recruiterFullName, id, executorType, parentId } =
    moderationRequestData;
  const dispatch = useAppDispatch();
  const isFormDisabled = useAppSelector(getModerationRequestStatus);
  const { email, phoneNumber, created } = useAppSelector(getProfile);
  const [actualExecutorType, setActualExecutorType] = useState<ExecutorTypeEnum>(
    executorType ?? ExecutorTypeEnum.Selfemployed
  );
  const isNavigateDisabled = useAppSelector(getExecutorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(moderationRequestActions.setIsNavigateDisabled(value));
  };
  const scrollDivRef = useRef<HTMLDivElement | null>(null);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<ModerationRequestForm>({
    defaultValues: {
      ...moderationRequestData,
      series: moderationRequestData.passport.series,
      number: moderationRequestData.passport.number,
      dateOfIssue: moderationRequestData.passport.dateOfIssue,
      issuedBy: moderationRequestData.passport.issuedBy,
      unitCode: moderationRequestData.passport.unitCode,
      files: moderationRequestData.passport.files,
      registrationAddress: moderationRequestData.passport.registrationAddress,
      birthPlace: moderationRequestData.passport.birthPlace,
      executorPhotos: moderationRequestData.executorPhotos.files,
      selfemployedCertificate:
        executorType === ExecutorTypeEnum.Selfemployed
          ? moderationRequestData.selfemployedCertificate.files
          : [],
      individualCertificateFiles:
        executorType === ExecutorTypeEnum.Individual
          ? moderationRequestData.individualCertificate.files
          : [],
      ogrnip:
        executorType === ExecutorTypeEnum.Individual
          ? moderationRequestData.individualCertificate.ogrnip
          : null,
      individualName:
        executorType === ExecutorTypeEnum.Individual
          ? moderationRequestData.individualCertificate.individualName
          : null,
      primaryEducation: moderationRequestData.educations?.filter((item) => item.isPrimary),
      secondaryEducation: moderationRequestData.educations?.filter((item) => !item.isPrimary),
      workExperiences: moderationRequestData.workExperiences,
      certificateElectricalSafety: moderationRequestData.certificateElectricalSafety.files,
      certificateWorkHeight: moderationRequestData.certificateWorkHeight.files,
      serviceCities: serviceCities?.map((item: any) => ({
        label: item.objectFullName,
        value: item,
      })),
    },
    mode: 'onBlur',
  });

  const actualFormValues = watch();

  const secondaryRequested = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: (
          <div className="mt-40 flex flex-col items-center justify-center gap-6 sm:mt-0">
            <p className="title-normal text-text-100">Заявка на изменение данных</p>
            <Button
              variant={ButtonVariant.OUTLINE}
              onClick={() => {
                dispatch(modalActions.closeModal());
                location.reload();
              }}
            >
              Закрыть
            </Button>
          </div>
        ),
        options: {
          modalClassName: 'w-full',
          withCloseButton: false,
        },
      })
    );
  };

  const showAd = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: <ShowAd />,
        options: {
          title: 'Скачивайте наше мобильное приложение',
          modalClassName: '!overflow-auto',
        },
      })
    );
  };

  const { refetch } = useQuery(
    ['getModerationRequest'],
    () => ModerationRequestService.getCurrentModerationRequest(),
    {
      enabled: false,
      onSuccess: async (data) => {
        if (
          [
            ModerationRequestStatusId.Draft,
            ModerationRequestStatusId.ElaborationRequested,
          ].includes(data.data.status)
        ) {
          dispatch(moderationRequestActions.setIsFormDisabled(false));
        } else {
          dispatch(moderationRequestActions.setIsFormDisabled(true));
        }
        dispatch(moderationRequestActions.setRequestStatus(data.data.status));
        dispatch(moderationRequestActions.setModerationRequest(data.data));
      },
    }
  );

  const { mutateAsync: createModerationRequest } = useMutation(
    ['createModerationRequest'],
    (body: ModerationRequest) => ModerationRequestService.createModerationRequest(body),
    {
      onSuccess: (data) => {
        if (data.data.executorType === ExecutorTypeEnum.Individual) {
          setValue('selfemployedCertificate', []);
        } else {
          setValue('ogrnip', null);
          setValue('individualCertificateFiles', []);
          setValue('individualName', null);
        }
        showAd();
        refetch();
      },
      onError: (data: AxiosError<ValidationError>) => {
        for (const error in data?.response?.data?.errors) {
          data?.response?.data?.errors[error].map((text: string) => toast.error(text));
          toast.error(data?.response?.data?.errors);
        }
      },
    }
  );

  const { mutateAsync: createSecondaryModerationRequest } = useMutation(
    ['createSecondaryModerationRequest'],
    (body: ModerationRequest) => ModerationRequestService.createSecondaryModerationRequest(body),
    {
      onSuccess: (data) => {
        if (data.data.executorType === ExecutorTypeEnum.Individual) {
          setValue('selfemployedCertificate', []);
        } else {
          setValue('ogrnip', null);
          setValue('individualCertificateFiles', []);
          setValue('individualName', null);
        }
        secondaryRequested();
        refetch();
      },
      onError: (data: AxiosError<ValidationError>) => {
        for (const error in data?.response?.data?.errors) {
          data?.response?.data?.errors[error].map((text: string) => toast.error(text));
          toast.error(data?.response?.data?.errors);
        }
      },
    }
  );

  const { mutateAsync: sendModerationRequest } = useMutation(
    ['sendModerationRequest'],
    (body: ModerationRequest) => ModerationRequestService.sendModerationRequest(body),
    {
      onSuccess: (data) => {
        if (data.data.executorType === ExecutorTypeEnum.Individual) {
          setValue('selfemployedCertificate', []);
        } else {
          setValue('ogrnip', null);
          setValue('individualCertificateFiles', []);
          setValue('individualName', null);
        }
        showAd();
        refetch();
      },
      onError: (data: AxiosError<ValidationError>) => {
        for (const error in data?.response?.data?.errors) {
          data?.response?.data?.errors[error].map((text: string) => toast.error(text));
          toast.error(data?.response?.data?.errors);
        }
      },
    }
  );

  const { mutateAsync: sendElaboration } = useMutation(
    ['sendElaboration'],
    (body: ModerationRequest) => ModerationRequestService.elaborateModerationRequest(body),
    {
      onSuccess: (data) => {
        if (data.data.executorType === ExecutorTypeEnum.Individual) {
          setValue('selfemployedCertificate', []);
        } else {
          setValue('ogrnip', null);
          setValue('individualCertificateFiles', []);
          setValue('individualName', null);
        }
        showAd();
        refetch();
      },
      onError: (data: AxiosError<ValidationError>) => {
        for (const error in data?.response?.data?.errors) {
          data?.response?.data?.errors[error].map((text: string) => toast.error(text));
          toast.error(data?.response?.data?.errors);
        }
      },
    }
  );

  const { mutateAsync: sendDraft } = useMutation(
    ['sendDraft'],
    (body: ModerationRequest) => ModerationRequestService.draftModerationRequest(body),
    {
      onSuccess: (data) => {
        if (data.data.executorType === ExecutorTypeEnum.Individual) {
          setValue('selfemployedCertificate', []);
        } else {
          setValue('ogrnip', null);
          setValue('individualCertificateFiles', []);
          setValue('individualName', null);
        }
        showAd();
        refetch();
      },
      onError: (data: AxiosError<ValidationError>) => {
        for (const error in data?.response?.data?.errors) {
          data?.response?.data?.errors[error].map((text: string) => toast.error(text));
          toast.error(data?.response?.data?.errors);
        }
      },
    }
  );

  const getRequestBody = (formData: ModerationRequestForm) => {
    const formValues = formData;

    if (typeof formValues.birthDate === 'object') {
      formValues.birthDate = formValues.birthDate && format(formValues.birthDate, 'yyyy-MM-dd');
    }
    if (typeof formValues.dateOfIssue === 'object') {
      formValues.dateOfIssue =
        formValues.dateOfIssue && format(formValues.dateOfIssue, 'yyyy-MM-dd');
    }

    if (formValues.primaryEducation?.length) {
      formValues.primaryEducation = trimDescription(formValues.primaryEducation);
    }
    if (formValues.secondaryEducation?.length) {
      formValues.secondaryEducation = trimDescription(formValues.secondaryEducation);
    }
    if (formValues.workExperiences?.length) {
      formValues.workExperiences = trimDescription(formValues.workExperiences);
    }

    const temp = {
      lastName: formValues.lastName,
      firstName: formValues.firstName,
      middleName: formValues.middleName,
      inn: formValues.inn,
      bik: formValues.bik,
      bankSettlement: formValues.bankSettlement,
      birthDate: formValues.birthDate ? formValues.birthDate : null,
      acceptsOffer: formValues.acceptsOffer ? formValues.acceptsOffer : false,
      executorType: actualExecutorType,
      fullName: `${formValues.lastName} ${formValues.firstName} ${formValues.middleName}`,
      citizenship: ECitizenship.RussianFederation,
      passport: {
        series: formValues.series ? formValues.series.replace(' ', '') : formValues.series,
        number: formValues.number,
        dateOfIssue: formValues.dateOfIssue ? formValues.dateOfIssue : null,
        issuedBy: formValues.issuedBy ? formValues.issuedBy.trim() : '',
        unitCode: formValues.unitCode ? formValues.unitCode.replace('-', '') : formValues.unitCode,
        files: formValues.files,
        registrationAddress: formValues.registrationAddress
          ? formValues.registrationAddress.trim()
          : null,
        birthPlace: formValues.birthPlace ? formValues.birthPlace.trim() : null,
      },
      executorPhotos: {
        files: formValues.executorPhotos,
      },
      selfemployedCertificate:
        actualExecutorType === ExecutorTypeEnum.Selfemployed
          ? {
              files: formValues.selfemployedCertificate,
            }
          : {
              files: [],
            },
      educations: [...formValues.primaryEducation, ...formValues.secondaryEducation],
      workExperiences: formValues.workExperiences,
      serviceCities: formValues.serviceCities
        ? formValues.serviceCities?.map((city) => city.value)
        : [],
      executorSpecializations: formValues.executorSpecializations,
      individualCertificate:
        actualExecutorType === ExecutorTypeEnum.Individual
          ? {
              ogrnip: formValues.ogrnip,
              individualName: formValues.individualName,
              files: formValues.individualCertificateFiles,
            }
          : {
              ogrnip: null,
              individualName: null,
              files: [],
            },
      certificateElectricalSafety: {
        files: formValues.certificateElectricalSafety,
      },
      certificateWorkHeight: {
        files: formValues.certificateWorkHeight,
      },
      userRegistration: formValues.userRegistration
        ? formValues.userRegistration
        : format(new Date(), 'yyyy-MM-dd'),
    };
    let requestBody: ModerationRequest;
    if (moderationRequestData) {
      const {
        created,
        id,
        moderatedUserId,
        parentId,
        recruiterId,
        status,
        rowVersion,
        recruiterFullName,
        pyrusUrl,
        checkLists,
      } = moderationRequestData;
      requestBody = {
        ...temp,
        id,
        moderatedUserId,
        recruiterId,
        created,
        parentId,
        status,
        rowVersion,
        recruiterFullName,
        pyrusUrl,
        checkLists,
        email,
        mobilePhone: phoneNumber,
      };
    } else {
      requestBody = {
        ...temp,
        id: 0,
        moderatedUserId: 0,
        lastName: '',
        firstName: '',
        middleName: '',
        recruiterId: 0,
        created: null,
        parentId: 0,
        status: 1,
        recruiterFullName: '',
        email,
        mobilePhone: phoneNumber,
        rowVersion: '',
        pyrusUrl: '',
        checkLists: {},
      };
    }
    return requestBody;
  };

  const submitHandler = async (requestHandler?: RequestHandler) => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
    clearErrors();
    const requestBody = getRequestBody(actualFormValues);
    const primaryEducation = requestBody?.educations?.filter((item) => item.isPrimary);
    const secondaryEducation = requestBody?.educations?.filter((item) => !item.isPrimary);

    const toastErrors: string[] = [];
    checkName(requestBody.firstName, 'firstName', 'Имя должно', toastErrors, setError);
    checkName(requestBody.lastName, 'lastName', 'Фамилия должна', toastErrors, setError);
    if (requestBody.middleName) {
      checkName(requestBody.middleName, 'middleName', 'Отчество должно', toastErrors, setError);
    }
    if (requestHandler === RequestHandler.MODERATION) {
      checkBirthDate(requestBody.birthDate, toastErrors, setError);
      checkDateOfIssue(requestBody.passport.dateOfIssue, toastErrors, setError);
      checkIssueBy(requestBody.passport.issuedBy, toastErrors, setError);
      checkBirthPlace(requestBody.passport.birthPlace, toastErrors, setError);
      checkRegistrationAddress(requestBody.passport.registrationAddress, toastErrors, setError);
      checkPassportFiles(requestBody.passport.files, toastErrors, setError);
      checkExecutorPhotos(requestBody.executorPhotos.files, toastErrors, setError);
      if (parentId === 0) {
        checkServiceCities(requestBody.serviceCities, toastErrors, setError);
      }
      checkExecutorSpecializations(requestBody.executorSpecializations, toastErrors, setError);
      checkAcceptsOffer(requestBody.acceptsOffer, toastErrors, setError);
      if (actualExecutorType === ExecutorTypeEnum.Selfemployed) {
        checkSelfemployedCertificate(
          requestBody.selfemployedCertificate.files,
          toastErrors,
          setError
        );
      }
    }
    if (requestHandler) {
      checkSeries(requestBody.passport.series, requestHandler, toastErrors, setError);
      checkNumber(requestBody.passport.number, requestHandler, toastErrors, setError);
      checkUnitCode(requestBody.passport.unitCode, requestHandler, toastErrors, setError);
      checkBikAndBankSettlement(
        requestBody.bankSettlement,
        requestBody.bik,
        requestHandler,
        toastErrors,
        setError
      );
    }
    if (requestHandler && actualExecutorType === ExecutorTypeEnum.Individual) {
      checkExecutorOgrnip(
        requestBody?.individualCertificate?.ogrnip,
        requestBody?.individualCertificate?.individualName,
        requestBody?.individualCertificate?.files,
        requestHandler,
        toastErrors,
        setError
      );
    }
    if (primaryEducation?.length && requestHandler) {
      primaryEducation.map((item: Education, index: number) => {
        checkExperience(item, index, requestHandler, toastErrors, setError);
      });
    }
    if (secondaryEducation?.length && requestHandler) {
      secondaryEducation.map((item: Education, index: number) => {
        checkExperience(item, index, requestHandler, toastErrors, setError);
      });
    }
    if (requestBody?.workExperiences?.length && requestHandler) {
      requestBody?.workExperiences.map((item: WorkExperience, index: number) => {
        checkExperience(item, index, requestHandler, toastErrors, setError);
      });
    }

    if (toastErrors.length) {
      const uniqueMessages = Array.from(new Set(toastErrors));
      uniqueMessages.forEach((message: string) => {
        toast.error(message);
      });
      return;
    }

    if (
      moderationRequestData?.id &&
      [ModerationRequestStatusId.Imported, ModerationRequestStatusId.TrainingCompleted].includes(
        status
      )
    ) {
      if (requestHandler === 'SECONDARY') {
        // console.log('createSecondaryModerationRequest393', requestBody);
        await createSecondaryModerationRequest(requestBody);
      } else {
        // console.log('sendElaboration396', requestBody);
        await sendModerationRequest(requestBody);
      }
    } else if (
      moderationRequestData?.id &&
      status === ModerationRequestStatusId.ElaborationRequested
    ) {
      // console.log('sendElaboration403', requestBody);
      await sendElaboration(requestBody);
    } else if (
      moderationRequestData?.id &&
      status === ModerationRequestStatusId.Draft &&
      requestHandler === 'DRAFT'
    ) {
      await sendDraft(requestBody);
      // console.log('sendDraft411', requestBody);
    } else if (
      moderationRequestData?.id &&
      status === ModerationRequestStatusId.Draft &&
      requestHandler === 'MODERATION'
    ) {
      await sendModerationRequest(requestBody);
      // console.log('sendModerationRequest418', requestBody);
    } else {
      // console.log('createModerationRequest420', requestBody);
      await createModerationRequest(requestBody);
    }
  };

  return (
    <PageWrapper
      title={
        moderationRequestData && moderationRequestData.id ? `Заявка №${id}` : `Заявка на модерацию`
      }
      withMark={moderationRequestData && moderationRequestData.parentId > 0}
      scrollDivRef={scrollDivRef}
    >
      <div className="flex flex-col gap-4 sm:gap-6">
        <div>
          <p className="callout-normal sm:body-normal !text-text-50">Статус:</p>
          <p className="body-bold sm:headline-bold !text-primary-60">
            {moderationRequestTitle[status]}
          </p>
        </div>
        <div className="flex items-center">
          <CashIcon className="mr-3.5" />
          <span className="body-bold sm:title2-bold text-text-100">Налоговый статус</span>
        </div>
        <div className="flex flex-col sm:max-w-[170px] sm:gap-6">
          <RadioGroup
            data={executorTypes}
            value={actualExecutorType}
            onChange={setActualExecutorType}
            disabled={status === 4 ? true : isFormDisabled}
          />
        </div>
        <div>
          <p className="callout-normal sm:body-normal !text-text-50">Дата регистрации:</p>
          <p className="body-bold sm:headline-bold text-text-100">
            {created && formattedDate(created)}
          </p>
        </div>
        <div>
          <p className="text-text-50">Рекрутер:</p>
          <p className="headline-bold">{recruiterFullName}</p>
        </div>
        <form className="flex flex-col sm:gap-6">
          <Dropdown title="Личные данные" Icon={UserIcon}>
            <Step1Fields
              clearErrors={clearErrors}
              control={control}
              isFormDisabled={isFormDisabled}
              errors={errors}
            />
          </Dropdown>
          <Dropdown title="Паспортные данные" Icon={GerbIcon}>
            <Step2Fields
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              isFormDisabled={isFormDisabled}
              disableNavigate={disableNavigate}
            />
          </Dropdown>
          <Dropdown title="Реквизиты" Icon={BankIcon}>
            <Step3Fields
              control={control}
              errors={errors}
              isFormDisabled={isFormDisabled}
              executorType={actualExecutorType}
              clearErrors={clearErrors}
              disableNavigate={disableNavigate}
            />
          </Dropdown>
          <Dropdown title="Специализации и территория работы" Icon={CaseIcon}>
            <Step4Fields
              parentId={parentId}
              control={control}
              watch={watch}
              isFormDisabled={isFormDisabled}
              errors={errors}
              clearErrors={clearErrors}
            />
          </Dropdown>
          <Dropdown title="Контактные данные" Icon={MobileIcon}>
            <Step5Fields />
          </Dropdown>
          <Dropdown
            title={
              actualExecutorType === ExecutorTypeEnum.Selfemployed ? 'Образование' : 'Опыт работы'
            }
            Icon={EducationIcon}
          >
            <Step6Fields
              control={control}
              watch={watch}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              isFormDisabled={isFormDisabled}
              executorType={actualExecutorType}
              disableNavigate={disableNavigate}
            />
          </Dropdown>
          <Dropdown className="sm:mb-6" title="Документы" Icon={ArchiveIcon}>
            <Step7Fields
              disableNavigate={disableNavigate}
              control={control}
              errors={errors}
              isFormDisabled={isFormDisabled}
            />
          </Dropdown>
          <Buttons
            isNavigateDisabled={isNavigateDisabled}
            status={status}
            parentId={parentId}
            submitHandler={submitHandler}
            id={id}
          />
          <div className="fixed right-0 bottom-[50px] mr-2 flex justify-end">
            {moderationRequestData &&
              moderationRequestData.status !== ModerationRequestStatusId.Draft && (
                <ChatModeration
                  entityType={ChatEntityType.ModerationRequestChat}
                  applicationID={ApplicationType.Onboarding}
                  entityID={moderationRequestData.id}
                  isMessageCanSent={[ModerationRequestStatusId.ElaborationRequested].includes(
                    moderationRequestData.status
                  )}
                />
              )}
          </div>
        </form>
      </div>
    </PageWrapper>
  );
};
