import { FieldCheckbox } from 'components/redesign/FieldCheckbox';
import { SpecSelect } from 'components/redesign/SpecSelect';
import { FC } from 'react';
import { downloadStaticFile, handleFileDownload } from 'utils/helpers';
import { StaticFiles } from 'utils/settings';
import { specsData } from './consts';
import { Step4Props } from './types';
import { CitiesSelect } from 'components/redesign/CititesSelect';

export const Step4Fields: FC<Step4Props> = ({
  control,
  watch,
  errors,
  isFormDisabled,
  parentId,
  clearErrors,
}) => {
  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      {parentId === 0 && (
        <CitiesSelect
          isMulti
          control={control}
          label="Города обслуживания"
          name="serviceCities"
          isFormDisabled={isFormDisabled}
          isClearable
          errorMessage={errors?.serviceCities?.message}
          clearErrors={clearErrors}
        />
      )}
      {parentId === 0 && (
        <SpecSelect
          name="executorSpecializations"
          control={control}
          label="Область специализации"
          options={specsData}
          watch={watch}
          disabled={isFormDisabled}
          errorMessage={errors?.executorSpecializations?.message}
          clearErrors={clearErrors}
        />
      )}
      <div>
        {parentId !== 0 && (
          <SpecSelect
            name="executorSpecializations"
            control={control}
            label="Область специализации"
            options={specsData}
            watch={watch}
            disabled={isFormDisabled}
            errorMessage={errors?.executorSpecializations?.message}
            clearErrors={clearErrors}
          />
        )}
        <div className="mt-6 mb-5 flex">
          <FieldCheckbox
            name="acceptsOffer"
            control={control}
            errorMessage={(errors as any)?.acceptsOffer?.message}
            title="Я принимаю оферту"
            disabled={isFormDisabled}
            clearErrors={clearErrors}
          />
        </div>
        <p>
          <span
            className="footnote-medium sm:callout-normal cursor-pointer !text-primary-60"
            onClick={() =>
              handleFileDownload(downloadStaticFile(StaticFiles.SelfEmployed_Contract_Offer))
            }
          >
            Ссылка на скачивание оферты
          </span>
        </p>
      </div>
    </div>
  );
};
