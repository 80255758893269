import { FC } from 'react';
import { Slider } from './Slider';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import './styles.css';

const Help: FC = () => {
  return (
    <PageWrapper title="Добро пожаловать">
      <Slider />
    </PageWrapper>
  );
};

export default Help;
